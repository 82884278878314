import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class MessageService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async sendEmail (payload) {
		const response = await this.axios.get("message/sendEmail", { params: { payload }});
		return response.data;
	}

}
